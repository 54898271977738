import { CSSProperties } from "react"

export const searchCont = {
  display: "flex",
  position: "relative",
  width: "100%",
  flexGrow: 1,
  transition: "all 3s ease, color 0.3s ease, border-color 0.3s ease",
  color: "var(--header-input)",
  "&:focus-within": {
    color: "var(--header-text)",
    transition: "color 0.3s ease",
  },
  "@media (min-width: 760px)": {
    maxWidth: "260px",
  },
  "@media (min-width: 960px)": {
    maxWidth: "360px",
  },
  "@media (min-width: 1200px)": {
    maxWidth: "394px",
  },
  "@media (min-width: 1600px)": {
    maxWidth: "594px",
  },
}

export const filterForHeader = (isOpenFilter: boolean): CSSProperties => ({
  maxWidth: "calc(100% - 16px)",
  maxHeight: "calc(100% - 140px)",
  position: "fixed",
  top: isOpenFilter ? "0" : "-10%",
  opacity: isOpenFilter ? 1 : 0,
  visibility: isOpenFilter ? "visible" : "hidden",
  transition: "all 0.4s ease-in-out",
  left: 0,
  zIndex: 98,
  margin: "78px auto",
})
