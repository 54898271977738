import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import Filtered from "../../filtered"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter"

import SearchIcon from "/src/assets/svg/header-icons-pack/Search-icon.svg?react"
import ClearIcon from "@mui/icons-material/Clear"
import FilterIcon from "/src/assets/svg/header-icons-pack/filter.svg?react"
import ActiveFilterIcon from "/src/assets/svg/header-icons-pack/filter-active.svg?react"

import { handleVisiblity } from "../headerNavStyles"
import { filterForHeader, searchCont } from "./headerSearchStyles"
import s from "./styles.module.scss"

const HeaderSearch = () => {
  const { pathname } = useLocation()
  const screenUserWidth = window.screen.width
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const rightPath = ["/", "/map"].some((p) => p === pathname)

  const { updateQueryText, currentFilter } = useEventFilter()
  const { text: inputText } = currentFilter

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    updateQueryText(text)
  }

  useEffect(() => {
    if (isOpenFilter) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
  }, [isOpenFilter])

  return (
    <Box sx={searchCont}>
      <input
        type="search"
        placeholder="Поиск..."
        className={s.search_input}
        value={currentFilter.text}
        onChange={handleSearchQuery}
      />

      <SearchIcon className={s.search_icon} />

      {screenUserWidth < 760 && !inputText && rightPath && (
        <>
          <FilterIcon
            onClick={() => setIsOpenFilter(true)}
            className={s.search_filter}
            style={handleVisiblity("filter-off", isOpenFilter)}
          />
          <ActiveFilterIcon
            onClick={() => setIsOpenFilter(false)}
            style={handleVisiblity("filter-on", isOpenFilter)}
          />
        </>
      )}

      <ClearIcon
        onClick={() => updateQueryText("")}
        className={s.search_delete}
        style={handleVisiblity("delete", !!inputText)}
      />

      {screenUserWidth < 760 && rightPath && (
        <Filtered
          extraStyles={filterForHeader(isOpenFilter)}
          setIsOpenFilter={setIsOpenFilter}
          isOpenFilter={isOpenFilter}
        />
      )}
    </Box>
  )
}

export default HeaderSearch
