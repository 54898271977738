import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import { CSSProperties } from "react"
import "/src/theme/colors.css"

//#region CardStyles
export const card: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  position: "relative",
  borderRadius: "16px",
  padding: "8px",
  backgroundColor: `var(--surface-bg)`,
  maxWidth: "100%",
}

export const cardImg: SxProps<Theme> = {
  position: "relative",
  padding: "0px 0px 51% 0px",
  marginBottom: "8px",
  borderRadius: "8px",
  backgroundColor: "var(--inputs-bg)",
  img: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}

export const cardCalendarContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.58)",
  position: "absolute",
  top: "7px",
  left: "16px",
  borderTopLeftRadius: "2px",
  borderTopRightRadius: "2px",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  cursor: "pointer",
}

export const cardCalendarMonthCont: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "16px",
  borderTopLeftRadius: "2px",
  borderTopRightRadius: "2px",
  backgroundColor: "var(--error-color)",
  color: `var(--white)`,
}

export const cardCalendarMonthText = { fontSize: "10px", fontWeight: 700 }

export const cardCalendarDayCont: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "32px",
  backgroundColor: `var(--calendar-fav-bg)`,
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  color: `var(--calendar-main-text)`,
}

export const cardCalendarDayText = { fontSize: "24px", fontWeight: 900 }

export const favIconContainer: SxProps<Theme> = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: "16px",
  right: "16px",
  backgroundColor: "var(--calendar-fav-bg)",
  borderRadius: "50%",
  cursor: "pointer",
}

export const favIcon: CSSProperties = {
  position: "absolute",
  top: "4px",
  right: "3.5px",
  stroke: "var(--error-color)",
  width: "17px",
  height: "16px",
}

export const cardContent = { padding: "0 !important", mb: "12px" }

export const cardTitleCont = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
}

export const cardTitle: SxProps = {
  fontWeight: 800,
  fontSize: "20px",
  lineHeight: "24px",
  margin: "0 !important",
  color: "var(--primary-text)",
  "&:first-letter": { textTransform: "uppercase" },
}

export const cardShareIcon = {
  flexShrink: "0",
  marginLeft: "11px",
}

export const cardInfoCont: SxProps<Theme> = {
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  paddingBottom: "8px",
  borderBottom: "1px solid var(--borders)",
  marginBottom: "8px",
}

export const cardInfo: SxProps<Theme> = {
  display: "flex",
  gap: "8px",
  alignItems: "center",
}

export const cardInfoIcon = { display: "contents" }

export const cardAvatarCont: SxProps<Theme> = {
  display: "flex",
  gap: "8px",
  width: "100%",
  alignItems: "center",
  mb: "12px",
  /* minWidth: "100%" */
}

export const cardBtnsCont = {
  display: "flex",
  gap: "8px",
  p: 0,
  m: 0,
  justifyContent: "space-between",
}

export const cardBtnImGoing = {
  width: "100%",
  borderRadius: "16px",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: 400,
  padding: "10px 8px 10px 8px",
  color: "var(--white)",
  background: "var(--btn-gradient)",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",

  "&:hover": {
    textDecoration: "none",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
}

export const cardBtnExtra = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "34.5px",
  background: "var(--btn-border-gradient)",
  padding: "5px 8px",
  border: "1.5px solid transparent",
  borderRadius: "20px",
  fontSize: "12px",
  marginLeft: "0 !important",
}

export const cardBtnExtraText: CSSProperties = {
  backgroundImage: "var(--btn-gradient)",
  backgroundClip: "text",
  color: "transparent",
}
//#endregion

//#region SCELETON STYLES
export const cardSceleton = { width: "100%" }

export const cardTitleContSceleton = { marginBottom: "8px" }

export const cardTitleSceleton: SxProps<Theme> = {
  width: "100%",
  height: "45px",
  borderRadius: "6px",
}

export const cardShareSceleton: SxProps<Theme> = {
  width: "40px",
  height: "45px",
  marginLeft: "8px",
  borderRadius: "6px",
}

export const cardInfoContSceleton = {
  gap: "0px",
}

export const cardInfoSceleton: SxProps<Theme> = {
  width: "100%",
  height: "30px",
}

export const cardDescSceleton: SxProps<Theme> = {
  width: "100%",
  height: "15px",
}

export const cardBtnsSubscrContSceleton: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
}

export const cardSubscrSceleton: SxProps<Theme> = {
  height: "40px",
  width: "40%",
}

export const cardBtnContSceleton: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  gap: "5px",
}

export const cardBtnSceleton: SxProps<Theme> = {
  width: "100%",
  height: "30px",
  borderRadius: "8px",
}
//#endregion
